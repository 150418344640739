import { render, staticRenderFns } from "./Login new.vue?vue&type=template&id=141ca964&scoped=true&"
import script from "./Login new.vue?vue&type=script&lang=js&"
export * from "./Login new.vue?vue&type=script&lang=js&"
import style0 from "./Login new.vue?vue&type=style&index=0&id=141ca964&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141ca964",
  null
  
)

export default component.exports